import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Annexe01 from "../components/assets/annexe01.png";
import Annexe02 from "../components/assets/annexe02.png";
// import Annexe03 from "../components/assets/annexe03.png";

function About() {
  return (
    <div className="card">
      <div className="card-body d-flex h-50 bd-highlight mb-3">
        <div className="row">
          <PerfectScrollbar>
            <div className="col-12 h-100 w-100">
              <Tabs
                defaultActiveKey="firstTab"
                id="technicsTabs"
                className="mb-3 tabsTecnics"
                fill
              >
                
                <Tab eventKey="firstTab" title="Reiki Usui Shiki Ryoho">
                  <div className="row h-100">
                    <div className="col-12 w-100">
                      <img
                        src={Annexe01}
                        style={{ height: "100%", width: "20%" }}
                        alt="annexe01"
                        id="annexe01"
                      />
                    </div>
                  </div>
                  <div className="h-100 d-flex align-items-center">
                    <div className="row h-100">
                      <div className="col-12 w-100">
                        <p>
                          <b>Rei : énergie cosmique <br />
                          Ki : énergie personnelle</b> <br />
                          <br />
                          
                            Il s’agit d’une méthode de soins non conventionnelle
                            d'origine japonaise, fondée sur des soins dits «
                            énergétiques » par imposition des mains. Elle a été
                            fondée par le Japonais Mikao Usui à la suite d’une
                            révélation mystique qui l’aurait conduit à recevoir
                            les « clefs de la guérison ».
                          
                        </p>

                        <p>
                          Les découvertes du manuel de Mikao Usui, le « Usui
                          Reiki Hikkei », de sa stèle funéraire déposée par ses
                          étudiants ainsi que la rencontre avec des proches de
                          Mikao Usui nous ont donné des éléments d’informations.
                          C’est également grâce à l’aide de Hiroshi Doi, maître
                          Reiki Japonais et membre de la Usui Reiki Ryoho
                          Gakkai, l’organisation fondée par Mikao Usui, ainsi
                          que de son ancienne dirigeante, madame Koyama, que
                          l’histoire suivante a pu être reconstituée.
                        </p>

                        <p>
                          Dans toutes les lignées Reiki, qu’elles soient
                          orientales ou occidentales, on raconte que Mikao Usui,
                          le fondateur du Reiki, aurait reçu un satori, ou
                          illumination, suite à une profonde ascèse de 21 jours
                          sur le mont Kurama, au nord de Kyoto. Suite à cela, il
                          aurait constaté la présence d’une nouvelle énergie
                          émanant de son corps. Cette énergie était la plus pure
                          et la plus bénéfique de tout ce qu’il connaissait auparavant. Il décida alors de nommer cette énergie «
                          Reiki » et d’en faire la base de son système, le « Usui Reiki Ryōhō ».
                        </p>

                        <p>
                          Ma Lignée Reiki : Dr Mikao Usui Sensei, Dr Ayashi,
                          Madame Takata, Iris Ishikuro, Reverend Arthur
                          Robertson, Roger Foisy, Michelle Terrien, Dama G..
                          Page, Maryvonne Fisson, Denise Chamois et Claire
                          Laurence Naullet, Marie-Annick Chevalier, Catherine
                          Sebald-L’Houe, Sylvain Naud, Olivier nMasson,
                          Katherine Husser.
                        </p>
                      </div>
                    </div>
                  </div>

                </Tab>
                <Tab eventKey="secondTab" title="LaHoChi">
                <div className="row h-100">
                    <div className="col-12">
                      <img
                        src={Annexe02}
                        style={{ height: "100%", width: "20%" }}
                        alt="annexe02"
                        id="annexe02"
                      />
                    </div>
                  </div>
                  <p>
                    {/* <u><b>LE SON :</b></u> <br /> */}
                    <b>
                      La : dans LaHoChi fait référence à la lumière, l’amour et
                      la sagesse venant des fréquences de la Lumière les plus
                      élevées
                    </b>{" "}
                    <br />
                    <b>
                      Ho : fait référence au mouvement de cette énergie
                    </b>{" "}
                    <br />
                    <b>
                      Chi : est la force de vie, à l’énergie universelle de vie
                    </b>{" "}
                    <br />
                  </p>

                  <p>
                    Le LaHoChi est une technique énergétique de soins très
                    puissante transmise par l’apposition des mains. Elle
                    apporte, par ce biais, une très haute fréquence de la
                    lumière de guérison qui, associée aux intentions du monde
                    spirituel et des êtres humains, permet à l&#39;énergie de se
                    déplacer partout où elle a besoin d&#39;aller. Elle est
                    considérée comme proche du Reiki.
                  </p>
                  <p>
                    Le LaHoChi (qui se prononce lah hoe chee) est une énergie
                    spirituelle aux fréquences vibratoires très élevées. Elle
                    permet d’accéder à une plus grande compréhension et
                    utilisation de l’Energie Universelle de Vie pour soi-même et
                    pour aider les autres, dans le respect du libre-arbitre de
                    chacun.
                  </p>
                  <p><u>
                      <b>Les Bienfaits du LaHoChi</b> <br />
                    </u></p>
                  
                  <p>
            

                    <p>
                      <li>
                        Ouvre les 21 centres d’énergie du corps ou le système
                        d’anatomie des méridiens,
                      </li>
                    </p>

                    <p>
                      <li>
                        Retisse le champ d’énergie en réparant les brèches, les
                        déchirures et les régions endommagées par une variété
                        d’influence incluant une naissance difficile, une
                        opération chirurgicale, la radiation, la drogue, les
                        accidents, les blessures anciennes et présentes de la
                        vie, tous les types de traumatismes, les intoxications
                        environnementales, les programmations négatives et les
                        tendances génétiques,
                      </li>
                    </p>

                    <p>
                      <li>Introduit des vibrations de couleurs et de sons,</li>
                    </p>
                    <p>
                      <li>
                        Améliore, dans le champ éthérique du corps, un
                        traitement tel que l’acupuncture par exemple,
                      </li>
                    </p>
                    <p>
                      <li>
                        Charge de vibrations les essences florales et minérales,
                      </li>
                    </p>
                    <p>
                      <li>
                        Equilibre la circulation du fluide crânien dans le
                        cerveau et équilibre le système cérébro- spinal,
                      </li>
                    </p>
                    <p>
                      <li>
                        Ouvre les blocages dans les corps d’énergies subtiles,
                      </li>
                    </p>
                    <p>
                      <li>Ouvre et nettoie le système des chakras,</li>
                    </p>
                    <p>
                      <li>
                        Réaligne la structure du corps tel un système de grille
                        multidimensionnelle qui maintient le corps dans son
                        intégrité,
                      </li>
                    </p>
                    <p>
                      <li>
                        Dénoue les blocages électriques dans l’énergie du corps,
                        semblable à un réseau de liens entre la mémoire
                        cellulaire et le corps physique, afin que l’information
                        puisse passer librement,
                      </li>
                    </p>
                    <p>
                      <li>Elargit le champ d’énergie,</li>
                    </p>
                    <p>
                      <li>
                        Place la mémoire divine dans la couche externe du champ
                        énergétique qui transmettra la mémoire divine au corps
                        entier,
                      </li>
                    </p>
                    <p>
                      <li>
                        Place la mémoire divine dans le champ éthérique dans le
                        but de guérir les déséquilibres,
                      </li>
                    </p>
                    <p>
                      <li>Active le corps de lumière,</li>
                    </p>
                    <p>
                      <li>
                        Accélère la guérison et les capacités psychiques
                        (clairvoyance, médiumnité, etc…).
                      </li>
                    </p>
                    <p>
                      Lors de votre séance de LaHoChi le praticien dispose de
                      cinq positions spécifiques de base où il place ses mains
                      sur le corps du Receveur. Elles couvrent la tête, le
                      torse, les bras, les hanches et la région des jambes et du
                      cœur. Avec de la pratique, il apprend à devenir
                      entièrement canal, à se fier à son intuition, et se laisse
                      guider pour envoyer l’énergie curative à une ou des zones
                      spécifiques sur le receveur.
                    </p>
                    <p>
                      Je vous accompagne également avec le soin LaHoChi à
                      distance en visio. Aussi le receveur pourra rester bien
                      confortablement installé chez lui. Je vous expliquerai
                      comment réactiver votre soin pendant 3 jours afin de
                      prolonger ses bienfaits.
                    </p>
                  </p>
                </Tab>
                {/* <Tab eventKey="thirdTab" title="LaHoChi de la 13ème octave">
                  <p>
                    Dans le manuel il nous est communiqué que le conseil d’amour
                    nous apprend que le LaHoChi renforce le Reiki de 100 X à lui
                    seul. Le Reiki a commencé l’activation, le LaHoChi poursuit
                    la purification de Mère- Terre et de ses habitants.
                  </p>
                  <p>
                    L’enseignement du Lahochi de la 13e Octave a été transmis
                    par un Maître spirituel du Chi – le Maître LAHOCHI – qui
                    existe dans les Royaumes Angéliques auprès du Maître
                    Spirituel Satchamar, canal spirituel et professeur, le 15
                    mai 1991. Leur union énergétique a permis d’activer ces
                    énergies spirituelles de guérison.
                  </p>
                  <p>
                    L&#39;énergie du Lahochi a de nouveau été réveillée par Lao
                    Tseu. Puis en 1996, c’est par le canal de Linda Dillon que
                    le Maître Lao Tseu s’est manifesté pour réintroduire les
                    vibrations du 13e Octave et ses bienfaits. Au début des
                    années 2000, Mme Dillon commença à canaliser le Maître
                    Saint-Germain. Sur sa demande elle fonda une école sacrée,
                    la renaissance du Temple d&#39;Atlantide de la Flamme
                    violette.
                  </p>
                  <p>
                    Pour le Lahochi de la 13e Octave : la séance complète dure
                    environ 1 heure avec 17 positions de mains différentes
                    fondées sur le système de 13 chakras. Il y a l&#39;ajout de
                    2 symboles, l&#39;accompagnement des 13 Maîtres ascensionnés
                    et le positionnement des Archanges en début de séance pour
                    la protection et guidance. Les positions d’activation des
                    mains ouvrent des portions inutilisées du cerveau ainsi que
                    les chakras spirituels du 14e au 16e chakra, la connexion
                    avec le cœur et l’Esprit Divin et l’ancrage du Moi Universel
                    dans la réalité physique. Ces positions travaillent aussi
                    avec la géométrie sacrée par le biais des triangles, la
                    forme diamant et la transmutation éthérique des pyramides.
                  </p>
                  <p>
                    <u>Signification de « 13e Octave »</u>{" "}
                  </p>
                  <p>
                    Les 13 Octaves font référence aux 13 fréquences vibratoires
                    associées aux 13 Maîtres ascensionnés qui accompagnent le
                    praticien et le client lors de la séance. Le système du
                    Lahochi de la 13e Octave est fondé sur un système de 13
                    chakras. Ainsi avec le Lahochi de la 13e Octave, nous avons
                    la possibilité de co- créer une puissante vibration avec un
                    cercle sacré de ces 13 Maîtres participants, appelés aussi
                    les Maîtres de la 13e Octave : Mère Marie, Sai Baba,
                    l&#39;essence de Bouddha, Maitreya, Kuthumi, Lao Tseu,
                    Saint-François d&#39;Assise, Sainte-Bernadette,
                    Saint-Germain, Sainte-Thérèse, Sainte-Catherine,
                    Wa&#39;ka&#39;na&#39;taka, Yahvé. De plus, ce cercle est
                    accompagné du Maître Jésus Sananda, ainsi que de plusieurs
                    Archanges dont Gabriel et Michael.
                  </p>

                  <p>
                    <u>
                      <b>Les bienfaits du Lahochi de la 13e Octave</b>
                    </u>
                  </p>
                  <p>
                    Les bienfaits sont nombreux et combinent les vibrations du
                    Lahochi traditionnel avec celles du Lahochi de la 13e
                    Octave. Le Lahochi agit sur la globalité de l’être dans ses
                    dimensions physique, émotionnel et mental. Il s’adresse à
                    toute personne qui souhaite améliorer son mieux être.
                  </p>

                  <p>
                    <li>
                      Retissage du champ d&#39;énergie (élimination des brèches,
                      fissures, trous dans l&#39;aura)
                    </li>
                  </p>
                  <p>
                    <li>
                      Introduction des vibrations puissantes de couleurs,
                      d&#39;essences florales et minérales
                    </li>
                  </p>
                  <p>
                    <li>Réunification du féminin et du masculin sacrés</li>
                  </p>
                  <p>
                    <li>Accélération du développement des dons spirituels</li>
                  </p>
                  <p>
                    <li>Libération de croyances limitantes</li>
                  </p>
                  <p>
                    <li>Activation du corps de lumière</li>
                  </p>
                  <p>
                    <li>Restructuration et équilibrage des corps subtils</li>
                  </p>
                  <p>
                    <li>Ouverture des 21 centres d&#39;énergie du corps</li>
                  </p>
                  <p>
                    <li>Paix intérieure</li>
                  </p>
                  <p>
                    <li>Méridiens renforcés</li>
                  </p>
                  <p>
                    <li>Relaxation profonde</li>
                  </p>
                  <p>
                    <li>Union avec le Cœur Divin</li>
                  </p>
                  <p>
                    <li>Augmentation de la conscience spirituelle</li>
                  </p>
                  <p>
                    <li>Ancrage du Soi Universel et de l’Âme</li>
                  </p>
                  <p>
                    <li>Libération des blocages émotionnels</li>
                  </p>
                  <p>
                    <li>
                      Ouverture, équilibrage et harmonisation des 13 chakras
                    </li>
                  </p>
                  <p>
                    <li>Visions pour le donneur et le receveur</li>
                  </p>
                  <p>
                    <li>
                      Accès à de plus hauts états de méditation ou de conscience
                      possible pour chaque personne dans le moment présent
                    </li>
                  </p>
                  <p>
                    <li>La 13e Octave permet l’accès à la 7e dimension</li>
                  </p>
                  <p>
                    <li>
                      La 13e Octave est la clé qui permet d’accéder à la
                      mémorisation de notre état pur de perfection, de
                      conscience, de savoir et d’amour, un état que nous
                      méritons tous.
                    </li>
                  </p>


                  <p><b><u>Ouverture et harmonisation des 13 chakras</u></b></p>

                  
                  <p><li>Chakra de la couronne</li></p>
                  <p><li>3<sup>ème</sup> œil</li></p>
                  <p><li>Chakra du sourcil</li></p>
                  <p><li>Chakra de la gorge</li></p>
                  <p><li>Chakra claviculaire</li></p>
                  <p><li>Chakra médio-sternal</li></p>
                  <p><li>Chakra du cœur</li></p>
                  <p><li>Chakra sternal inférieur</li></p>
                  <p><li>Plexus solaire</li></p>
                  <p><li>Chakra sacral</li></p>
                  <p><li>Chakra de la rate</li></p>
                  <p><li>Chakra pubien</li></p>
                  <p><li>Chakra de base</li></p>
                </Tab> */}
{/* 
                <Tab eventKey="fourthTab" title="Magnétiseuse">
                <div className="row h-100">
                    <div className="col-12 w-100">
                      <img
                        src={Annexe03}
                        style={{ height: "100%", width: "20%" }}
                        alt="annexe03"
                        id="annexe03"
                      />
                    </div>
                  </div>
                        <p>
                        Pranic Healing (magnétisme pranique)
                        </p>
                        <p>J’utilise cette pratique afin de ré harmoniser votre aura lors d’un soin, en faisant des passes magnétiques,
avec mes mains, puis mon pendule nous accompagnera pour un nettoyage et un réalignement de vos
chakras.</p>
                </Tab> */}
              </Tabs>

              {/* <i>
            <div id="logoContainer">
              <img src={Logo} alt="logo" />
            </div>
            
              <p className="d-flex pt-5 h4">
                J’allie toutes mes techniques en énergétique afin de vous
                apporter un soin adapté à votre demande et à vos besoins. Ce
                moment sera dédié uniquement à votre bien-être, afin d’en
                apprécier tous les bienfaits, dans la plus grande
                bienveillance, l’écoute et la confidentialité.
              </p>
            </i> */}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}

export default About;
