import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import "./App.css";
import Home from "./pages/Home";
import WhoAmI from "./pages/WhoAmI";
import Prices from "./pages/Prices";
import About from "./pages/About";
import Legals from "./pages/Legals";
import Sophrologie from "./pages/Sophrologie";

// import PerfectScrollbar from "react-perfect-scrollbar";



function App() {
  return (
    <Router>
      <div className="App h-screen">
        
          <div className="background-image"></div>
          <Navbar />

          <main id="mainContainer" className="container mx-auto px-3 pb-12">
            <Routes>
              
                <Route path="/home" element={<Home />} />
                <Route path="/whoami" element={<WhoAmI />} />
                <Route path="/prices" element={<Prices />} />
                <Route path="/sophrologie" element={<Sophrologie />} />
                <Route path="/about" element={<About />} />
                <Route path="/legals" element={<Legals />} />

                <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
          </main>

          <Footer />
        
      </div>
    </Router>
  );
}

export default App;
