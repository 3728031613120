import PerfectScrollbar from "react-perfect-scrollbar";
import MyPhoto from "../components/assets/Me.png";
function WhoAmI() {
  return (
    <div className="card overflow-auto">
      <PerfectScrollbar>
        <div className="card-body d-flex card-text bd-highlight mb-3 h-95 mobileUnsetHeight">
          <div>
            <div className="row d-flex h-100" >
                <div
                  className="pe-5 ps-5 align-items-center mobileNoPadding mobileFontSizeLarger col-md-12 col-lg-9"
                  style={{ fontSize: "23px", fontStyle: "italic" }}
                >
                  <p>
                    Après 16 ans à partager le quotidien d’un établissement de
                    santé, tant avec les patients qu’avec mes collègues. 16 ans
                    à constater qu’il est parfois difficile de rester
                    indifférent, face à la douleur, au stress et au mal être des
                    personnes qui croisent votre route ou vous côtoient.
                  </p>

                  <br />
                  <p>
                    Une passionnée mais aussi une empathique. Les soins
                    énergétiques m’ont accompagnée depuis mon enfance. Puis vint
                    l’émergence de cette petite voix intérieure qui vous pousse
                    à aller au-delà et à regarder derrière le rideau. Derrière
                    ce rideau, calme et sérénité, une harmonie, une ouverture
                    d’esprit et le chemin vers les médecines douces «  dites
                    alternatives ». Le sentiment que mes mains avaient des
                    choses à dire et à transmettre.{" "}
                  </p>
                  <br />
                  <p>
                    C’est ainsi qu’en 2019 J’ai obtenu mon 1<sup>er</sup> degré
                    de Reiki, puis le 2<sup>ème</sup> en 2020. En 2021 j’ai reçu
                    l’enseignement et l’initiation à la technique du LaHoChi
                    traditionnelle et récemment dans la pratique de la 13
                    <sup>ème</sup> octave. En 2022, j’ai souhaité compléter mes
                    soins énergétiques en me formant au magnétisme pranique
                    (le Pranic Healing).
                  </p>

                  <br />
                  <p>
                    Depuis le mois d’octobre 2022 j'ai débuté une formation en
                    sophrologie.
                  </p>
                </div>
                <div className="col-lg-3 col-sm-3 col-3 col-md-3" id="MyPhotoContainer">
                  <img src={MyPhoto} alt="Maphoto" id="MyPhoto" />
              </div>
              </div>


            </div>
          </div>
      </PerfectScrollbar>
    </div>
  );
}

export default WhoAmI;
