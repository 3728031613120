import PerfectScrollbar from "react-perfect-scrollbar";

function Legals() {
  return (
    <div className="card overflow-auto">
      <PerfectScrollbar>
        <div className="card-body card-text bd-highlight mb-3 h-95">
          <div className="d-flex justify-content-between align-items-end h-100" style={{display: 'flex!important','flex-direction': 'column'}}>
            <div>
            <h2><b> MENTIONS LÉGALES</b></h2>
            <br />
            <p>
              Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Au détour de soi l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
            </p>
            <br />

            <h2><b>Edition du site</b></h2>
            <br />
            <p>
            Le présent site, accessible à l’URL <a href="http://www.audetourdesoi.com">ici</a>, est édité par :

‌

Stéphanie EHRET, résidant 15 rue Edgar heywang 67140 BOURGHEIM, de nationalité Française (France), né(e) le 04/11/1972,

            </p>
<br />
            <h2><b>Hébergement</b></h2>
            <br />
            <p>
            Le Site est hébergé par la société Netlify, situé 2325 3rd St Ste 296, 94107 San Francisco, Californie, Etats-Unis, (contact téléphonique ou email : +14156911573).
            </p>

            <br />

            <h2><b>Directeur de publication</b></h2>

            <br />

            <p>Le Directeur de la publication du Site est Stéphanie EHRET.</p>

            <br />

            <h2><b>Nous contacter</b></h2>

            <br />
            <p>
            Par téléphone : +33675656348
<br />
Par email : [ehret.stephanie@gmail.com](mailto:ehret.stephanie@gmail.com "‌")
<br />

Par courrier : 15 rue Edgar heywang 67140 BOURGHEIM
            </p>
<br />

<br />
<div style={{fontSize:11}}>Génération des mentions légales par <a href="https://www.legalstart.fr/">[Legalstart]</a>. </div>
          </div>
        </div></div>
  </PerfectScrollbar>
    </div>

  )
}

export default Legals