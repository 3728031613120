import PerfectScrollbar from "react-perfect-scrollbar";
import imgSophro from "../components/assets/ImageSophro.png";
function Sophrologie() {
  return (
    <div className="card overflow-auto">
      <PerfectScrollbar>
        <div className="card-body card-text bd-highlight mb-3 h-95">
            <div>
              <p>
                <i>
                  <h4>
                    <b>{`"Au détour de soi c’est aussi prendre soin de soi grâce à la sophrologie "`}</b>
                  </h4>
                  <br />
                  {/* <p>{`Créée dans les années 60 par le Professeur Caycédo (neuropsychiatre), la méthode allie différentes approches orientales et occidentales, permettant l’harmonisation du corps et de l’esprit.`}</p>
                  <p>{`La sophrologie est une méthode dite « psychocorporelle » basée sur la respiration contrôlée, la détente musculaire, ainsi que des visualisations positives. `}</p>
                  <p>{`Elle peut être pratiquée par tous, sous forme d’un accompagnement visant à améliorer le bien-être, le potentiel et/ou la qualité de vie des personnes suivies selon différentes thématiques.`}</p>
                  <p>{`Mon rôle sera de vous écouter et de mettre à votre disposition toutes mes connaissances en qualité de sophrologue, aussi l’accompagnement sera personnalisé, adapté à vos besoins et à vos capacités.  `}</p> */}

                  <p>{`Créée dans les années 60 par le Professeur Caycédo (neuropsychiatre), la méthode allie différentes approches orientales et occidentales, permettant l’harmonisation du corps et de l’esprit.`}</p>
                  <p>{`La sophrologie est une méthode dite « psychocorporelle » basée sur la respiration contrôlée, la détente musculaire, ainsi que des visualisations positives. C’est également une méthode de relaxation guidée où l’on entraîne l’esprit à se focaliser sur le moment présent amenant à faire émerger un sentiment de bien-être.`}</p>
                  <p>{`Elle est totalement verbale et non tactile. Elle peut être pratiquée par tous, sous forme d’un accompagnement « personnel » et « adapté » permettant d’améliorer son potentiel, d’avoir davantage confiance en soi, et/ou d’acquérir des outils en matière de gestion du stress.`}</p>
                  <p>{`Mon rôle sera de vous écouter et de mettre à votre disposition toutes mes connaissances en tant que sophrologue, et de définir avec vous un accompagnement personnalisé, adapté à vos besoins et à vos capacités.`}</p>
                  <div>
                    <img src={imgSophro} alt="imgSophro" style={{maxWidth:'100%',maxHeight:'100%'}} />
                  </div>
                  <h5>
                    <u>
                      <b>{"Déroulement de la séance"}</b>
                    </u>
                  </h5>
                  <br />
                  <div style={{ fontSize: "18px" }}>
                    <p>{`La première séance est un moment d’échange et d’écoute, permettant de définir le type d’accompagnement qui sera le mieux adapté. Puis d’exercices de relaxation.  `}</p>
                    <p>{`Les séances suivantes, démarreront toujours par un temps d’échange puis d’exercices de relaxation et pour clôturer notre séance, vous serez invité à exprimer vos ressentis avec bienveillance.`}</p>
                    <p>{`Les différents exercices se pratiquent assis ou debout. Prévoyez d’être à l’aise dans votre tenue. `}</p>
                  </div>
                  <br />
                  <h4>
                    <u>
                      <b>{"Tarifs"}</b>
                    </u>
                    <br />
                  </h4>
                  <br />
                  <div style={{ fontSize: "20px" }}>
                    <b>Adulte : </b> <br />
                    60€ la 1ère séance (1h15/30) <br />
                    50€ les séances suivantes (1h) <br /> <br />
                    <b>Enfant :</b>
                    <br />
                    50€ la 1ère séance (1h15/30) <br />
                    40€ les séances suivantes <br />
                    <br />
                    <b>Groupe :</b>
                    <br />
                    15€ par personne et par séance <br />
                    50€ pour un mois complet <br />
                    <br />
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    <p>
                      {`Prise en charge par certaines mutuelles « `}
                      <a href="https://www.chambre-syndicale-sophrologie.fr/mutuelle-sophrologie-sophrologue/" target={'_blank'} rel="noreferrer">
                       {`Ici`} 
                      </a>
                      {` »`}
                    </p>
                  </div>
                  {/* TODO: Ajouter photo sur la droite des tarifs - annexe04.png */}
                </i>
              </p>
            </div>
            <div style={{ fontSize: "13px" }}>
              <p style={{ border: "1px solid grey" }}>
                <i>
                  {`« Conformément aux articles L.616-1 et R.616-1 du code de la consommation, nous proposons un dispositif de médiation de la consommation. 
                  L'entité de médiation retenue est : CNPM - MEDIATION DE LA CONSOMMATION. En cas de litige, vous pouvez déposer votre réclamation sur son site : https://cnpm-mediation-consommation.eu ou par 
                  voie postale en écrivant à CNPM - MEDIATION - CONSOMMATION - 27 avenue de la libération - 42400 Saint-Chamond »`}
                </i>
              </p>
              <br />
            </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}

export default Sophrologie;
