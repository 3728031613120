import PerfectScrollbar from "react-perfect-scrollbar";
import Logo from "../components/assets/Logo.png";
import ImagePiece01 from "../components/assets/piece004.jpg";
import ImagePiece02 from "../components/assets/piece001.jpg";
//import { useEffect } from "react";
//import fbLogo from "../components/assets/fbLogo.png";

// import Logo from "../components/assets/Logo.png";


// function resamerde(d, s, id) {
//   var js, fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)){
//     run();
//     return;
//   } 
//   js = d.createElement(s); js.id = id;
//   js.src = "https://www.resalib.fr/script/66527/badge.js";
//   fjs.parentNode.insertBefore(js, fjs);
// }

function Home() {
  
  // useEffect(() => {
  //   // resamerde;
  //   resamerde(document, "script", "resalib-sdk");
  // });

  // const a = resamerde(document, "script", "resalib-sdk");

  let body = `<div style="text-align:center;z-index:9998;background-color:#ffffff38;border-radius:5px;padding:3px;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;max-width:400px;">
  <a target="_blank" style="background-color:#4caf50 !important;border:none;color:#fff !important;padding:10px;margin:5px;text-align:center;text-decoration:none;display:inline-block;font-size:18px;margin-left:5px;" class="rlb-rdv" href="https://www.resalib.fr/praticien/66527-ehret-stephanie-praticien-en-soins-energetiques-bourgheim#newrdvmodal">
        <img src="https://www.resalib.fr/app/images/ico/clock.svg" style="box-shadow:none;vertical-align:middle;height:22px;display:inline"> Prendre rendez-vous
  </a>
</div>`
  
  return (
    <div className="card overflow-auto">
        <PerfectScrollbar>
        <div className="card-body card-text bd-highlight mb-3 h-95">
        <div className="h-100 mobileNoMargin" style={{ margin: "20px 20px 20px 20px" }}>
          <div className="row h-50 mobileBlock">
            <div className="col-3 h-100 d-flex align-items-center logoHomeContainer">
              <img
                src={Logo}
                alt="logoHome"
                id="logoHome"
                style={{ "max-width": "100%", "max-height": "92%" }}
              />
            </div>
            <div className="col h-100 d-flex align-items-center mobileFontSizeLarger" style={{fontSize: 'x-large'}}>
              <i>
              Un objectif, un évènement, ou une amélioration du bien-être dans votre quotidien… <br />
              La sophrologie peut devenir votre allié de tous les jours. <br />

Je peux vous accompagner avec des outils et des moyens adaptés à vos besoins.
                {/* "Praticienne en soins énergétiques. Je vous propose un moment de
                détente lors d’une séance individuelle. <br /> Je pratique
                essentiellement le LaHoChi qui, comme le Reiki, permet d’apaiser
                le corps et l’esprit, et d’harmoniser l’énergie qui circule dans
                vos Chakras" */}
              </i>
            </div>
          </div>

          <div className="row h-50 w-100">
            <div className="col d-flex w-100 carteVisite align-items-center">
              <div className="text-start mobileCentrer">
                <p className="my-auto">
                  <i>
                    <div style={{fontSize: '24px'}}><b>EI Stéphanie EHRET</b> <br /></div>
                    <div style={{fontSize: '18px'}}><b>Sophrologue </b><br /> </div>
                    Praticienne en Reiki Usui - LaHoChi traditionnel et de la 13ème octave - Magnétiseuse<br />
                    15 RUE EDGAR HEYWANG <br />
                    67140 BOURGHEIM <br />
                    <b>Sur rendez-vous : </b>
                    <a href="tel:+33675656348">06 75 65 63 48</a> <br />
                    Mail :{" "}
                    <a href="mailto:audetourdesoi@gmail.com">
                      audetourdesoi@gmail.com
                    </a>{" "}
                    <br />
                    Siret : 913 649 802 00018 <br />
                    {/* <a
                      href="https://www.facebook.com/audetourdesoi"
                      target={"__blank"}
                    >
                      <img
                        src={fbLogo}
                        alt="fbLogo"
                        style={{ height: "20px", width: "20px" }}
                      ></img>{" "}
                    </a>
                    <br /> */}
                    </i>
                    
<div className="badge-container" dangerouslySetInnerHTML={{__html: body}}> 
</div>

              
                </p>
              </div>
            </div>
            <div className="col-4 h-100 ImagePiece01Container">
              <img
                src={ImagePiece01}
                style={{ "max-width": "100%", "max-height": "92%" }}
                alt="imagePiece01"
                id="imagePiece01"
              ></img>
            </div>
            <div className="col-4 h-100 ImagePiece02Container">
              <img
                src={ImagePiece02}
                style={{ "max-width": "100%", "max-height": "92%" }}
                alt="imagePiece02"
                id="imagePiece02"
              ></img>
            </div>
          </div>
          {/* <i>
                          <div id="logoContainer">
                            <img src={Logo} alt="logo" />
                          </div>
                          
                            <p className="d-flex pt-5 h4">
                              J’allie toutes mes techniques en énergétique afin de vous
                              apporter un soin adapté à votre demande et à vos besoins. Ce
                              moment sera dédié uniquement à votre bien-être, afin d’en
                              apprécier tous les bienfaits, dans la plus grande
                              bienveillance, l’écoute et la confidentialité.
                            </p>
                          </i> */}
        </div>
        </div>
    </PerfectScrollbar>
      </div>
  );
}

export default Home;
