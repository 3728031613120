import PerfectScrollbar from "react-perfect-scrollbar";
function Prices() {
  return (
    <div className="card overflow-auto">
      <PerfectScrollbar>
        <div className="card-body card-text bd-highlight mb-3 h-95">
          <div className="d-flex justify-content-between align-items-end h-100" style={{display: 'flex!important','flex-direction': 'column'}}>
            <div>
              <p>
                <i>
                  <h4>
                    <u>
                      <b>{"Déroulement de la séance"}</b>
                    </u>
                  </h4>
                  <br />
                  <div style={{fontSize: '18px'}}>
                  <u>
                    <b>{`Une séance dure en moyenne 1h à 1h30,`}</b>
                  </u>{`nous aurons un temps d’échange suivi d’un soin énergétique.
                  Vous serez allongé sur une table de soin, habillé, et couvert
                  d’une petite laine pour votre confort. Pour un soin LaHoChi
                  vos mains seront posées sur vos hanches. Et vous n’aurez plus
                  qu’à apprécier les bienfaits d’un soin énergétique que vous
                  aurez choisi. `}
                   {/* <br /> <br />
                  {`Le manuel nous apprend que le conseil d’amour nous enseigne
                  que le LaHoChi renforce le Reiki de 100 X à lui seul. Le Reiki
                  a commencé l’activation, le LaHoChi poursuit la purification
                  de Mère-Terre et de ses habitants. `}
                  <b>
                    {"Ne soyez donc pas surpris si je privilégie cette méthode. "}
                    
                  </b> */}
                  <br />
                  <br />
                  <i>
                  {" Pour les soins à distance, un échange téléphonique ou envisio au préalable est recommandé. "}
                  </i>
                  </div>
                  <br />
                  <br />
                  <h4>
                    <u>
                      <b>
                      {"Soin LaHoChi avec recentrage de l’Aura et rééquilibrage des Chakras"}
                        
                      </b>
                    </u>
                    <br />
                  </h4>
                  <br />

                  



                  <div style={{fontSize:'20px'}}>
                    <b>Adulte : </b> 60€ <br />
                    Offre 4 séances 200€ soit 50€ la séance <br />
                    Soin à distance 50€ <br /> <br />
                    <b>Enfant :</b> jusqu’à 16 ans 40 € <br />
                    Offre 4 séances 120€ soit 30€ la séance <br />
                    Soin à distance 30€ <br />

                  </div>
                   <br /> <br />
                   <h4>
                    <u>
                      <b>
                      {"Soin collectif à distance de Pleine Lune et/ou de Nouvelle Lune (1h)"}
                        
                      </b>
                    </u>
                    <br />
                  </h4>

                  <div style={{fontSize:'20px'}}>
                   <br />
                    <b> Tarif unique de :</b> 40€
                  </div>



                  {/* TODO: Ajouter photo sur la droite des tarifs - annexe04.png */}
                </i>
              </p>
            </div>
            <div style={{fontSize: '13px'}}>
              <p style={{border: '1px solid grey'}}>
                <i>
                {`Mes pratiques ne sont en aucun cas associées à une religion, un mouvement ou une dérive sectaire. 
                Chacun(e) est libre de ses choix et de ses pensées, et ce système de guérison naturel se veut respectueux des croyances de chacun et ouvert à tous. 
                Ces systèmes énergétiques ne peuvent se substituer à un avis ou à un traitement médical (et ce en aucune façon). 
                Tout avis ou démarche contraire doivent être considérés comme une atteinte grave à l’éthique ainsi qu’à la déontologie requise dans le cadre d’un soin, 
                d’une initiation, ou plus largement dans la pratique de ces enseignements dans leur ensemble.`}
                </i>
              </p>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}

export default Prices;
