import { NavLink } from "react-router-dom";


function Navbar() {
  return (
    <div className="d-flex w-100">
      <nav className="navbar d-flex justify-content-center navbar-expand-lg navbar-light w-100">
        <div className="navbar-nav row w-75 d-flex justify-content-center">

          <NavLink
            to="/home"
            className="nav-item nav-link col"
            style={({ isActive }) => ({ textDecoration: isActive ? "overline" : "none" })}
          >
            <h3 className="navBarMenu colorWhite">Accueil</h3>
          </NavLink>

          <NavLink
            to="/whoami"
            className="nav-item nav-link col"
            style={({ isActive }) => ({  textDecoration: isActive ? "overline" : "none" })}
          >
            <h3 className="navBarMenu">Qui suis-je</h3>
          </NavLink>

          <NavLink
            to="/prices"
            className="nav-item nav-link col"
            style={({ isActive }) => ({  textDecoration: isActive ? "overline" : "none" })}
          >
            <h3 className="navBarMenu">Tarifs</h3>
          </NavLink>

          <NavLink
            to="/sophrologie"
            className="nav-item nav-link col"
            style={({ isActive }) => ({  textDecoration: isActive ? "overline" : "none" })}
          >
            <h3 className="navBarMenu">Sophrologie</h3>
          </NavLink>

          <NavLink
            to="/about"
            className="nav-item nav-link col"
            style={({ isActive }) => ({  textDecoration: isActive ? "overline" : "none" })}
          >
            <h3 className="navBarMenu">LaHoChi Reiki</h3>
          </NavLink>

        </div>
      </nav>

      <style></style>
    </div>
  );
}

export default Navbar;
